import { Fragment } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import BackgroundVideo from '@activebrands/core-web/components/BackgroundVideo';
import Image from '@activebrands/core-web/components/Image';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import Slider from '@activebrands/core-web/components/Slider';
import overlay from '@activebrands/core-web/libs/overlay';
import createSlider from '@activebrands/core-web/libs/slider/create-slider';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import MediaNavigation from 'templates/ProductPage/MediaGallery/MediaNavigation';

const Wrapper = styled(ScrollableBox, {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap: '16px',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    background: 'var(--color-bg)',
});

const ZoomGalleryOverlay = ({ media = [] }) => {
    const [css] = useStyletron();
    const slider = createSlider();

    if (media.length < 1) {
        return null;
    }

    return (
        <Wrapper>
            <ThemeButton
                $style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '0 8px',
                    justifyContent: 'center',
                    position: 'fixed',
                    right: '0',
                    top: '0',
                    zIndex: 1,
                }}
                theme="defaultsecondary"
                onClick={() => overlay.close('zoom-gallery')}
            >
                {fm('Close')}
                <CloseIcon />
            </ThemeButton>
            <MediaNavigation buttonTheme="default" media={media} slider={slider} />
            <div className={css({ height: '100%', gridColumn: '2 / span 10', position: 'relative' })}>
                <Slider
                    isDraggable
                    isInfinite
                    $style={{ height: '100%' }}
                    slider={slider}
                    slidesPerView={1}
                    snapAlign="none"
                    spaceBetween="4px"
                >
                    {media.map(({ url, type }, index) => (
                        <Fragment key={url}>
                            {type === 'image' && (
                                <Image
                                    $style={{
                                        backgroundColor: 'var(--color-bg-image)', // backgroundColor prevents background-repeat effect on lazy load
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    loading={index === 0 ? 'eager' : 'lazy'}
                                    sizes={['100vw']}
                                    src={{ url, width: [400, 600, 800, 1000, 1200] }}
                                />
                            )}
                            {type === 'video' && (
                                <div
                                    className={css({ position: 'relative', width: '100%', height: '100%', zIndex: 1 })}
                                >
                                    <BackgroundVideo
                                        $style={{
                                            aspectRatio: 'var(--ratio-vertical-primary)',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                        src={url}
                                    />
                                </div>
                            )}
                        </Fragment>
                    ))}
                </Slider>
            </div>
        </Wrapper>
    );
};

ZoomGalleryOverlay.propTypes = {
    media: PropTypes.object,
};

export default ZoomGalleryOverlay;
