import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import ArrowIcon from 'components/icons/ArrowIcon';
import Thumbnails from 'templates/ProductPage/MediaGallery/Thumbnails';

const Wrapper = styled('div', {
    display: 'none',

    [media.min['desktop.sm']]: {
        position: 'absolute',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: '50%',
        bottom: '12px',
        gap: '24px',
        zIndex: 'var(--zindex-pdp-gallery)',
    },
});

const MediaNavigation = ({ $style = {}, buttonTheme = 'default', media = [], slider = () => null }) => (
    <Wrapper $style={$style}>
        <ThemeButton
            $style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                height: '40px',
                zIndex: 1,
                padding: 0,
                margin: 0,
            }}
            theme={buttonTheme}
            onClick={() => slider.slideToPrev()}
        >
            <ArrowIcon $style={{ transform: 'rotate(180deg)' }} size="16px" />
        </ThemeButton>
        <Thumbnails $style={{ position: 'relative' }} items={media} slider={slider} />
        <ThemeButton
            $style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                height: '40px',
                zIndex: 1,
                padding: 0,
                margin: 0,
            }}
            theme={buttonTheme}
            onClick={() => slider.slideToNext()}
        >
            <ArrowIcon size="16px" />
        </ThemeButton>
    </Wrapper>
);

MediaNavigation.propTypes = {
    $style: PropTypes.object,
    buttonTheme: PropTypes.string,
    media: PropTypes.array,
    slider: PropTypes.func,
};

export default MediaNavigation;
